.masoul-tabs-container .tabs-container {
  padding: 2% 0%;
}

.tabs-container {
  display: flex;
  background: #fff;
  margin-top: -16px;
}

.tabs-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.tabs-container li {
  list-style: none;
  color: #4f4f4f;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  transition: all ease-in-out 300ms;
  padding-bottom: 10px;
}

li.tab-active-item {
  color: #844254;
  font-weight: 600;
  border-bottom: 2px solid #844254;
}

.masoul-user-avatar-container {
  position: relative;
}

.masoul-user-avatar-edit-hover {
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 25%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.input-testt:hover .masoul-user-avatar-edit-hover {
  opacity: 1 !important;
  cursor: pointer;
}

.input-add-avatar {
  display: none;
}

.input-testt {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pp-profil-user-visiteur {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.masoul-dash-admin-section-edit-profil-prenom-item-deux {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 13.4991px;
  line-height: 18px;
  color: rgba(24, 24, 25, 0.9);
}

.admin-masoul-btn-edit {
  display: inline-flex;
  padding: 15px 60px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 40px;
  background: #498494;

  color: #fff;
  font-family: "Raleway";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.admin-masoul-btn-edit:hover {
  background: #fff;
  color: #498494;
  border: 1px solid #498494;
}

.custom-role-user-admin {
  color: #000;
  text-align: center;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-name-user-admin {
  color: #000;
  text-align: center;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.masoul-dash-admin-section-edit-profil-item {
  display: flex;
  /* justify-content: space-between; */
}

.masoul-dash-admin-section-edit-profil-prenom {
  width: 35%;
}

.masoul-dash-admin-section-edit-profil-prenom-item {
  color: #666;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
  text-transform: capitalize;
}

.masoul-dash-admin-section-edit-profil-prenom-item-deux {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 13.4991px;
  line-height: 18px;
  color: rgba(24, 24, 25, 0.9);
}

.masoul-dash-admin-section-edit-profil-btn-item-deux {
  color: #666;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
}

.masoul-form-label {
  color: #3B4758;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.masoul-custom-form-divider {
  color: rgba(196, 196, 196, 0.9);
}

.close-modal {
  border: none;
  background: none;
}

.close-modal {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #666666;
  background: #E4E6EB;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-modal-header {
  padding: 25px !important;
  border-bottom: none;
}

.auth-submit-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 45px;
  gap: 10px;
  background: #498494;
  border-radius: 50px;

  color: #FFF;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  letter-spacing: 2px;
  text-transform: capitalize;
  color: #ffffff;
}

.auth-submit-btn:hover {
  color: #498494;
  border: 1px solid #498494;
  background: #fff;
}

.custom-btn-annuler-modale {
  padding: 5px 10px;
  border: none;
  background: none;

  color: #4F4F4F !important;
  font-family: "Roboto" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

input.form-control.form-control.form-control-modal-custom.custom-padding {
  padding-top: 20px;
}

.react-tel-input .flag-dropdown {
  border: none !important;
}

/* .form-control-modal-custom-input-tel {
    padding-left: 22px !important;
    background: none !important;
    border-radius: 50px !important;
    border: 1px solid #E0E0E0 !important;
    color: #444150 !important;
  
    font-family: "Inter" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: normal !important;
 }
 .form-control-modal-custom-input-tel::placeholder {
    color: #ABABAB !important;
    font-weight: 400 !important;
  } */