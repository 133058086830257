.custom-sm-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.custom-sm-top-title {
  color: #2d3748;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
.custom-add-item-btn {
  color: #531132;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: transparent;
  border: 1px solid transparent;
  padding: 12px;
  transition: .4s ease-in;
  box-shadow: 0px 12px 26px 0px #101e730f;
}
.custom-add-item-btn:hover {
  border-color: rgba(82, 26, 42, 1);
}
.search-user-box input {
  color: #8c8ca1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 20px;
  background: #f2f3f3;
  padding: 15px 40px;
  min-width: 350px;
  border: 0;
}
.search-user-box input:focus {
  border: 0 !important;
  box-shadow: none;
  outline: none;
}
.icon-span-search-svg {
  position: absolute;
  left: 10px;
  top: 10px;
}

.card_content .profil__grid {
  display: grid;
  place-items: center;
  row-gap: 1.5rem;
}

.card_content .user_profil img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}

.card_content .user_name {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.card_content .user_points {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card_content .btm_border {
  border-bottom: 1px solid rgba(196, 196, 196, 0.50);;
}

.card_content .user_infos__row p {
  color: #666;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
}

.card_content .user_infos__row p.sm_value {
  font-weight: 600;
}

.go_back_wrapper {
  margin-top: -1.9rem;
}

.go_back_wrapper a {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.go_back_wrapper a {
  text-decoration: none;
}

.go_back_wrapper a:hover {
  color: #158a91;
}

.bg_colgate {
  background-color: #fff;
}

form .choose_photo__box {
  border: 1px solid #bbb;
  width: 145px;
  height: 145px;
  margin: 0 auto;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

form .custom_close__btn,
form .custom_submit__btn {
  color: #FFF;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 12px 38px;
  border: 1px solid transparent;
  transition: .3s ease;
}

form .custom_close__btn {
  color: #4F4F4F;
}

form .custom_submit__btn {
  border-radius: 50px;
  background: #158a91;
}

form .custom_submit__btn:hover {
  background: transparent;
  color: #158a91;
  border-color: #158a91;
}

.commands_details__container .product_number__infos {
  color: #191C1F;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.commands_details__container .command_total__price {
  color: #521A2A;
  text-align: right;
  font-family: "Public Sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
}

.commands_details__container .custom_command_details__header {
  background: rgba(132, 66, 84, 0.10);
  border-radius: 4px;
}

.btn-back {
  all: inherit;
  cursor: pointer;
}