.tabs_container {
    font-family: Poppins;
    font-style: normal;
    line-height: normal;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: -0.4rem;
    column-gap: 2.5rem;
}

.tabs_container .tab_item {
    color: #4F4F4F;
    font-size: 16px;
    font-weight: 400;
}

.tabs_container .tab_item__active {
    color: #844254;
    font-size: 16px;
    font-weight: 600;
}

.tabs_container .tab_item__active::after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    bottom: -12px;
    width: 95%;
    height: 2px;
    background: #844254;
    margin: 0 auto;
}

.activities .activity_card {
    padding: 0.8rem 1.5rem;
    font-family: "Public Sans";
    font-style: normal;
    border-radius: 5px;
}

.activities .activity_card .icon__wrapper {
    padding: 0.5rem;
    border-radius: 2px;
    background: #fff;
}

.activities .activity_card img {
    width: 32px;
    height: 32px;
}

.activities .activity_card .counter {
    color: #191C1F;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
}

.activities .activity_card .label {
    color: #475156;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.commands_history .title__box {
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #E4E7E9;
    background: #fff;
    padding: .8rem 1rem;
}

.commands_history h4 {
    color: #191C1F;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
}