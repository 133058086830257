.content-category-project-detail {
  background: #521a2a1a;
  padding: 20px;
  width: 100%;
  height: max-content;
}

.project_category .sm_title {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #521a2a;
}

.project_category .sm_value {
  font-family: "Inter";
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}

.dashbord-admin-component {
  width: 100%;
}

.content-text-description .text-description-ellipsis {
  display: inline;
  /* -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word; */
}

.text-description-ellipsis p,
.text-description-ellipsis ol,
.text-description-ellipsis ul,
.text-description-ellipsis pre,
.text-description-ellipsis blockquote,
.text-description-ellipsis h1,
.text-description-ellipsis h2,
.text-description-ellipsis h3,
.text-description-ellipsis h4,
.text-description-ellipsis h5,
.text-description-ellipsis h6 {
  color: #000;
  line-height: 18px;
  text-wrap: balance;
}

.project-detail-video-player {
  box-shadow: 0px 4px 19px 0px #00000014;
}

.project-detail-video-player>div {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.aribic-input .ql-editor {
  direction: rtl;
  text-align: right;
}