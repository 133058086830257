.container-auth-login-component {
  position: relative;
  background: #f5f5f5;
  min-height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.content-auth-component {
  width: 45%;
  margin: auto;
  padding: 30px;
  border-radius: 10px;
  background: #fff;
  position: relative;
  z-index: 9;
}

.show-hide-password-valide {
  position: absolute;
  right: 25px;
  color: #c4c4c4;
  top: 12px;
  font-size: 24px;
}
.show-hide-password {
  position: absolute;
  right: 25px;
  color: #c4c4c4;
  top: 12px;
  font-size: 24px;
}
.forget-password-link {
  color: #711241;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
}

.forget-password-link:hover {
  color: #498494;
  font-weight: bold;
}
.container-login-form-info {
  display: flex;
  justify-content: flex-end;
}

.form-control-login {
  border-radius: 50px;
  background: #f7f7f7;
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 25px 70px;
  border: 0;
}
.form-control-login::placeholder {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.content-icon-input-group-login {
  position: absolute;
  left: 25px;
  top: 20px;
}
.title-login-page {
  color: #000;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.content-btn-submit-login {
  width: 60%;
  margin: auto;
}
.btn-theme {
  border-radius: 50px;
  background: #498494;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 15px;
}
.btn-theme:hover {
  color: #498494;
  border: 1px solid #498494;
  background: #fff;
}
