.card_content .command_number {
    color: #191C1F;
    font-family: "Public Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.card_content .sm_title {
    color: #333;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.card_content .sm_value {
    color: rgba(0, 0, 0, 0.70);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.card_content .reduce_size {
    font-size: 14px;
}

.card_content .command_date_and_deliver_status__infos .separator {
    width: 1px;
    height: 20px;
    background: #D0D5DD;
}

.card_content .delivered {
    color: #12B76A;
    font-weight: 600;
}

.card_content .confirmed {
    color: rgb(21, 138, 145);
    font-weight: 600;
}

.card_content .inprogress {
    color: rgba(244, 166, 98);
    font-weight: 600;
}

.card_content .cancel {
    color: rgba(235, 87, 87);
    font-weight: 600;
}

.card_content .claimed {
    color: #00c2cb;
    font-weight: 600;
}