.page-content-base-container {
  transition: 0.75s;
  overflow: auto;
  width: 100% !important;
  display: flex;
  justify-content: flex-end;
}

.user-dash-page-content-container {
  background: #ffffff;
  padding: 20px 34px;
}

.item-stat-dashboad-view {
  padding: 10px 20px;
  width: 100%;
  position: relative;
  align-items: center;
  border-radius: 10px;
  background: #fff;
  height: 100%;
}

.titre-item-stat-dashboad-view {
  color: #747474;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.chiffre-item-stat-dashboad-view {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.content-text-item-stat-dashboad-view {
  width: 100%;
}

.admin-page-container-layout {
  margin-top: 95px;
}

.content-stat-dashboard-view {
  display: flex;
  align-items: center;
  gap: 30px;
}

.content-chart-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-control-select-date-graph {
  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
  border-radius: 100px;
  /* background: url(../../../assets/icons/Shape.png) 96% 50% / 13px no-repeat
    #fff; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.4px;
  color: #252733;
  width: 300px;
  padding: 15px;
  border: 0;
}

.chart-item-admin-title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.btn-export-data-dashboard {
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.07);
  padding: 12px;
  color: #531132;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-export-data-dashboard:hover {
  background: #521a2a;
  color: #fff;
  border: 0;
}

.title-content-dash-admin {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
}

.container-title-content-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-content-dash-admin {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
}

.content-graph-admin {
  background: #fff;
  padding: 20px;
  box-shadow: 0px 12px 26px 0px #101e730f;
  border-radius: 10px;
  position: relative;
}

.text-subtitle-graph {
  font-family: "Lato";
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.10000000149011612px;
  color: #a0a4a8;
}

.content-col-lg-date-filter {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.form-label-date-filter {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.form-control-date-filter {
  background: transparent;
  border: 0;
  color: #909090;
  text-transform: capitalize;
}

.form-control-date-filter:focus {
  box-shadow: none;
  border: 0;
  background: transparent;
}

.tabs-title-content-dashboard-data {
  display: flex;
  list-style: none;
  gap: 1rem;
  padding-left: 0;
}

.tab-item-dashboard {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 10px;
  border: 1px solid #b1b1b1;
  background: #f6f6f6;
  position: relative;
  padding: 15px;
  display: grid;
  place-items: center;
  flex-basis: 100%;
}

.tab-item-dashboard-active {
  border-radius: 10px;
  border: 1px solid #521a2a;
  background: #f6eff3;
  color: #521a2a;
  font-weight: 500;
}

.bl-color0 {
  border-left: 5px solid #ebebeb;
}

.bl-color1 {
  border-left: 5px solid #e71d73;
}

.bl-color2 {
  border-left: 5px solid #498494;
}

.bl-color3 {
  border-left: 5px solid #db242a;
}

.bl-color4 {
  border-left: 5px solid #274e98;
}

.bl-color5 {
  border-left: 5px solid #e58924;
}

.bl-color6 {
  border-left: 5px solid #d03e96;
}