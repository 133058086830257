.btn-action-filter-icon,
.btn-action-filter-icon:hover {
  color: #084cb3;
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-action-icon-delete,
.btn-action-icon-delete:hover {
  color: #fa1414;
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-action-icon-edit,
.btn-action-icon-see,
.btn-action-icon-add-point,
.btn-action-icon-edit:hover,
.btn-action-icon-see:hover
.btn-action-icon-add-point:hover {
  color: #476DB6;
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-action-modal-icon,
.btn-action-modal-icon:hover {
  color: #158a91;
  border: none;
  background: #c2e9ec;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.btn-action-icon-add-point,
.btn-action-icon-delete,
.btn-action-icon-see,
.btn-action-icon-edit {
  border-radius: 50%;
}
.btn-action-icon-add-point,
.btn-action-icon-delete {
  background: #FA141413;
  border-radius: 50%;
}
.btn-action-icon-see {
  background: rgba(2, 180, 192, 0.22);
}
.btn-action-icon-edit {
  background: rgb(232, 235, 240);
}
.btn-action-modal-icon::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 77%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #158a91;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.btn-action-modal-mail,
.btn-action-modal-mail:hover {
  color: #219653;
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.btn-action-modal-mail::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 77%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #219653;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.btn-action-filter-icon::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 77%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #084cb3;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.btn-action-icon-delete::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 77%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #fa1414;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.btn-action-icon-edit::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 77%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #476DB6 !important;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.btn-action-icon-add-point::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 77%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #521A2A !important;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.btn-action-icon-see::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 77%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #158a91 !important;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.with-tooltip {
  position: relative;
}
.with-tooltip:hover::after {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, 0);
}
.statut-commande {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  padding: 15px;
}
.accepte {
  background: #effef8;
  color: #08bb90;
}
.annule {
  color: #eb5757;
  background: rgba(250, 20, 20, 0.1);
}

.admin-table thead {
  border-bottom: 1px solid rgb(247, 247, 251);
  height: 50px;
}
.admin-table table thead tr th {
  color: #010235;
  text-align: justify;
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
}
.admin-table table tbody tr td,
.admin-table table tbody tr td p {
  color: rgba(0, 0, 0, 0.70);
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.admin-table .table thead tr th,
.admin-table .table tbody tr td {
  text-align: left;
  vertical-align: middle;
  overflow-wrap: break-word;
}
.admin-table .table tbody tr {
  background: #FAF9F7;
  border-radius: 0px;
  border-bottom: 3px solid #FFF;
  height: 70px;
}
.admin-table .table {
  border: transparent;
  --bs-table-bg: transparent !important;
}
.img-profil-user-table {
  border-radius: 6px;
  width: 40px;
  height: 40px;
}
.name-profil-table {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 18px;
  color: #3b4758 !important;
}
table thead tr th {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 50px;
  text-align: justify;
  color: #010235;
}
table tbody tr td,
table tbody tr td p {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.7);
}
.table thead tr th,
.table tbody tr td {
  text-align: left;
}
.table {
  border: transparent;
}
table tbody tr {
  background: #faf9f7;
  border-bottom: 1px solid #fff;
}
.table thead {
  background: transparent !important;
}
.table > :not(:first-child) {
  border-top: none !important;
}
.react-bootstrap-table table {
  table-layout: auto;
}
.table thead tr th,
table tbody tr td {
  padding: 20px 25px !important;
  vertical-align: middle;
}

.react-bootstrap-table table {
  table-layout: auto;
}
.img-project-table {
  width: 39px;
  height: 39px;
  border-radius: 3.172px;
}
.nom-projet-admin-table {
  color: rgba(0, 0, 0, 0.80) !important;
} 
.admin-table {
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
}

.admin-table .command-status-formatter p {
  font-weight: 600;
}

.admin-table .command-status-formatter .inprogress {
  color: #F2994A;
}

.admin-table .command-status-formatter .confirmed {
  color: #498494;
}

.admin-table .command-status-formatter .cancel {
  color: #EB5757;
}

.admin-table .command-status-formatter .delivered {
  color: rgba(9, 210, 138);
}

.admin-table .command-action-formatter a {
  text-decoration: none;
  color: #844254;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.admin-table .command-action-formatter a span {
  margin-left: 5px;
}

.admin-table .command_product_image__wrapper img,
.admin-table .product_image__wrapper img {
  border-radius: 5px;
  object-fit: cover;
}

.admin-table .command_product_image__wrapper img {
  width: 55px;
  height: 52px;
}

.admin-table .product_image__wrapper img {
  width: 50px;
  height: 50px;
}

.admin-table .status__box {
  border-radius: 5px;
  padding: 8px 10px;
  text-align: center;
  white-space: nowrap;
}

.admin-table .status__box.delivered {
  background: rgba(9, 210, 138, 0.22);
}

.admin-table .status__box.inprogress {
  background: rgba(244, 166, 98, 0.22);;
}

.admin-table .status__box.cancel {
  background: rgba(235, 87, 87,0.22);
}

.admin-table .status__box.confirmed {
  background: rgba(2, 180, 192, 0.22);
}

.admin-table .status__box.claimed {
  background: rgba(26, 181, 190, 0.1);
}

.admin-table .category_name {
  color: #2DA5F3;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
}

.product_table__wrapper .product__counter {
  color: #191C1F;
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.product_table__wrapper .product__counter span {
  color: #5F6C72;
}


.switch2,
.switch input {
  width: min-content;
  /* padding: 50px; */
  /*flex*/
  display: flex;
  justify-content: center;
  align-items: center;
  /* Position */
  position: relative;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.switch input {
  display: none;
}
.slider {
  width: 75px;
  height: 28px;
  background-color: #c4c4c48f;
  display: block;
  border-radius: 50px;
  position: relative;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  cursor: pointer;
}

.slider::before {
  content: "OUI";
  width: 30px;
  height: 95%;
  background-color: #21a374;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  transition: transform 0.8s, background-color 2s;
}

input:checked + .slider {
  background-color: #c4c4c4;
}

input:checked + .slider::before {
  content: "NON";
  background-color: #cf3918;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(calc(75px - 24px - 8px));
}

.switch2 .slider {
  border-radius: 3px;
}

.switch2 .slider::before {
  border-radius: 0;
}
.custom-btn-see-more {
  border: none;
  background: none;
  color: #844254;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}

a.claim-order:hover .product_name, a.claim-order:hover .name-profil-user-table {
  color: #521A2A;
}

.custom-table-row-class.claim {
  background-color: #d2afb951 !important;
}