.masoul-parametre-admin-cgu p {
    color: #000;
    /* text-align: justify; */
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
}
.masoul-btn-action-table {
    border: none;
    background: none;
    color: #ACABAB;
}
.masoul-btn-action-table svg {
    font-size: 20px;
}

.modal.show {
    z-index: 9999;
}

.custom-editor-politique-confidentialite .ql-editor {
    min-height: 300px;
}