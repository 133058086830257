.colgate__wrapper img {
    width: 100%;
    height: 210px;
    border-radius: 16px;
    object-fit: cover;
}

.add_product__form form .form-control-modal-custom,
.add_product__form form textarea {
    border-radius: 35px !important;
    background: #FCFCFC !important;
    padding: 15px 25px !important;
}

.react-select-custom__input {
    background: 0px center !important;
    padding: 0px !important;
}

/* .add_product__form form .css-13cymwt-control {
    border-radius: 35px !important;
    background: #FCFCFC !important;
    padding: 2px 8px !important;
} */
.react-select-custom__control {
    border: 0.25px solid #959595;
    /* filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.08)); */
    border-radius: 35px !important;
    background: #FCFCFC !important;
    /* height: 60px; */
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.025em;
    color: rgba(0, 0, 0, 0.5);
    padding: 8px;
}

.react-select-custom__control input {}

.add_product__form form input:focus,
.add_product__form form textarea:focus,
.add_product__form form .css-13cymwt-control:focus {
    box-shadow: none;
    border-color: #844254 !important;
}

.big__size {
    font-size: 24px !important;
    font-weight: 600 !important;
}

.categories_tab__content .category_item {
    border-radius: 40px;
    border: 1.082px solid #498494;
    background: #fff;
    padding: 7px 15px;
    align-items: center;
    justify-content: space-between;
}

.product-img-card {
    background: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    position: relative;
}

.no-style-btn {
    border: none;
    background-color: transparent;
}

.del-product-img {
    position: absolute;
    top: 3px;
    right: 3px;
    display: none;
}

.product-img-card:hover .del-product-img {
    display: block;
}

#file-preview img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.name-container {
    display: flex;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    object-fit: fill;
}

.img-category {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.product-ql-description {
    background-color: #FCFCFC !important;
}