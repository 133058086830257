body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.no-link,
.no-link:hover,
.no-link.active {
  text-decoration: none;
  color: inherit;
  border: inherit !important;
}

._2Jtxm {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

._2JvrO svg {
  font-size: 10px !important;
  width: 15px !important;
  height: 15px !important;
}

.edk-alert {
  border-left: 6px solid #09132d;
  border-radius: 0.4rem;
  color: #09132d;
  padding: 2rem 1%;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-start;
}

.edk-alert-info {
  background-color: rgba(50, 175, 239, 0.1);
}

.edk-alert h4 {
  margin: 0;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 18px;
}

/* .pagination {
  position: relative;
  align-items: center;
  margin-top: 40px;
  justify-content: center;
}
.page-item.active .page-link {
  background-color: #e42d29 !important;
  border-color: #e42d29 !important;
  color: #fff !important;
  font-weight: 900;
}

li.page-item a {
  color: #000 !important;
}

.page-item:first-child .page-link span {
  color: #000 !important;
}

.page-item.disabled .page-link span {
  color: #000 !important;
} */

.pagination {
  position: relative;
  align-items: center;
  margin-top: 40px;
  justify-content: center;
}

.page-item.active .page-link {
  background-color: #844254 !important;
  border-color: #844254 !important;
  color: #fff !important;
  color: #FFF;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

li.page-item a {
  color: #000 !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  color: var(--gRIS, #7E7E7E) !important;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.page-item:first-child .page-link span {
  color: #000 !important;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-item.disabled .page-link span {
  color: #000 !important;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  color: #1B1B1B;
}

.swal2-backdrop-show {
  z-index: 999999 !important;
}

.error-page-section-row {
  padding: 50px 0 150px 0;
  align-items: center;
}

.error-page-section-title {
  color: #084cb3 !important;
}

.error-page-textual p {
  color: #084cb3 !important;
}

.return-to-homepage-btn {
  background: #084cb3 !important;
  color: #ffffff !important;
  padding: 10px 30px;
  border-radius: 15px;
  border-color: #084cb3 !important;
  transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
}

.error-page-code-textual-container {
  padding: 100px;
}

.error-page-code {
  font-size: 200px;
  color: #084cb3 !important;
}

#error-page-section {
  background: #f4f9f8;
}

.return-to-homepage-btn:hover {
  background: transparent !important;
  color: #084cb3 !important;
  border-radius: 50px;
}

.statut-cabinet-actif {
  background: #CFF5EA;
  padding: 5px 20px;
  border-radius: 5px;
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  color: #279089;
}

.statut-cabinet-inactif {
  background: #edd4d4;
  padding: 5px 20px;
  border-radius: 5px;
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  color: #e42d29;
}

.react-select-option-custom__control {
  background: #E6F3F0 !important;
  border: 1px solid #E6F3F0 !important;
  border-radius: 5px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #444150 !important;
  min-height: 48px !important;
}

.ql-editor {
  min-height: 150px;
}

.statut-rdv-en_attente {
  background: #c0c9e8;
  padding: 5px 20px;
  border-radius: 5px;
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  color: #2a71d5;
}

/* 2a71d5 */
.statut-rdv-en_cours {
  background: #e8e1d8;
  padding: 5px 20px;
  border-radius: 5px;
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  color: #FCAE3B;
}

.statut-rdv-terminer {
  background: #CFF5EA;
  padding: 5px 20px;
  border-radius: 5px;
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  color: #279089;
}

.statut-rdv-annuler {
  background: #edd4d4;
  padding: 5px 20px;
  border-radius: 5px;
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  color: #e42d29;
}

.custom-close-rdv-btn {
  display: inline-flex;
  padding: 13.038px 20px;
  justify-content: center;
  align-items: center;
  gap: 10.029px;
  border-radius: 10.029px;
  border: 1px solid var(--primary-dark, #099876);
  background: #099876;
  transition: background .3s ease;
  color: var(--neutral-black, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

.custom-close-rdv-btn:hover {
  background: #fff;
  color: #099876;
}

.custom-close-rdv-btn span.add-icon {
  font-size: 18px;
  color: #fff;
}

.custom-close-rdv-btn:hover span.add-icon {
  color: #099876;
}

.react-datepicker__current-month,
.react-datepicker__day-names {
  text-transform: capitalize;
}

.react-select-with-select-medecin__menu {
  z-index: 99999999;
}

.fc .fc-timegrid .fc-daygrid-body,
.fc .fc-timegrid-body {
  z-index: 0 !important;
}

.search-results ul {
  padding: 10px 0 0 0;
}

.search-results ul li {
  list-style: none;
}

.search-results {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 99;
  /* border: 1px solid #dbf0eb; */
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 11% !important;
}

.praticien-result-search {
  padding: 5px 10px;
}

.praticien-result-search:hover {
  background-color: rgb(09, 152, 118, 0.3);
  color: #1a1a1a;
  cursor: pointer;
}

.resultat-recherche-user-infos-specialite {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: #515151;
}

.resultat-recherche-user-infos-adresse,
.resultat-recherche-user-infos-adresse a {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 142.69%;
  text-align: justify;
  letter-spacing: 0.03em;
  color: #8c8ca2;
  text-underline-offset: 2px;
}

.Toastify__toast-theme--light {
  background-color: #099876 !important;
  color: #fff !important;
}

.swal2-confirm {
  background-color: #099876 !important;
}

.react-select-option-custom__value-container div {
  width: auto !important;
}