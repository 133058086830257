.card-voucher-admin {
    border-radius: 14px;
    background-color: #521A2A;
    padding: 15px 10px;
    min-height: 10rem;
    width: 100%;
}
.card-voucher-admin-title {
    color: #FFF;
    font-family: "Inter";
    font-size: 15.691px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.657px;
}
.card-voucher-admin-amount {
    color: #FFF;
    font-family: "Inter";
    font-size: 23.934px;
    font-style: normal;
    font-weight: 900;
    line-height: 160%;
    width: 100%;
}
.card-voucher-admin-amount span {
    color: rgba(255, 255, 255, 0.80);
    font-family: "Inter";
    font-size: 20.794px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}
.card-voucher-admin-content {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.card-voucher-admin-eticket {
    display: flex;
    flex-direction: row;
    transform: rotate(-15.321deg) translateY(-22px);
    border-radius: 6.09px;
    background: #FFF;
    width: 55%;
    height: 70px;
}
.card-voucher-admin-eticket-beige {
    width: 38.398px;
    height: 100%;
    /* transform: rotate(-17.321deg); */
    /* flex-shrink: 0; */
    border-radius: 6.09px 0px 0px 5.853px;
    background: #9A6F7B;
}
.card-voucher-admin-eticket-white {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.card-voucher-admin-eticket-white-point {
    color: #521A2A;
    font-family: "Inter";
    font-size: 23.934px;
    font-style: normal;
    font-weight: 700;
    line-height: 37.61px;
}
.card-voucher-admin-eticket-white-point-label {
    color: #521A2A;
    font-family: "Inter";
    font-size: 10.659px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.704px;
}
.card-voucher-admin-custom-right-dot {
    position: absolute;
    top: 50%;
    right: -10px;
    width: 16px;
    height: 16px;
    background-color: #521A2A;
    border-radius: 50%;
    transform: translate(0, -50%);
}
.card-voucher-admin-custom-left-dot {
    position: absolute;
    top: 50%;
    left: -10px;
    width: 16px;
    height: 16px;
    background-color: #521A2A;
    border-radius: 50%;
    transform: translate(0, -50%);
}
.card-voucher-admin-wrapper {
    border-radius: 10px;
    background: #FFF;
}