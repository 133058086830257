.container-cgu-component {
    position: relative;
    background: #f5f5f5;
    min-height: 100vh;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.content-cgu-component {
    width: 90%;
    margin: auto;
    padding: 30px;
    border-radius: 10px;
    background: #fff;
    position: relative;
    z-index: 9;
}

.cgu-content p{
    color: #000;
    /* text-align: justify; */
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    text-wrap: balance;
}